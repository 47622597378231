import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormAnswerValues, FormChanges, FormComponentGroup, FormDefinitionComponent, ValueLogicResult } from '@features/configure-forms/form.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { UserService } from '@features/users/user.service';
import { SiblingValueValidator, TypeToken } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { LogicBuilderService } from '../logic-builder.service';
import { ConditionalLogicResultType, EvaluationType, GlobalValueLogicGroup, LogicColumn, LogicColumnDisplay, LogicGroupType, LogicValueFormatType, RelativeDateCalculationConfig } from '../logic-builder.typing';
import { SelectOption, TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';

export interface ValueLogicBuilderFormGroup<T, V> {
  resultType: ConditionalLogicResultType;
  setResult: LogicColumn<T>;
  staticResult: V;
  applyCalculation: boolean;
};

@Component({
  selector: 'gc-value-logic-builder',
  templateUrl: './value-logic-builder.component.html',
  styleUrls: ['./value-logic-builder.component.scss']
})
export class ValueLogicBuilderComponent<T> implements OnInit, OnChanges {
  @Input() formId: number;
  @Input() availableColumns: LogicColumnDisplay<T>[];
  @Input() component: FormDefinitionComponent;
  @Input() sourceColumn: LogicColumnDisplay<T>;
  @Input() options: (TypeaheadSelectOption<string>|SelectOption<string>)[] = [];
  @Input() logicValueFormatType: LogicValueFormatType;
  @Input() logic: GlobalValueLogicGroup<T, ValueLogicResult<T>>;
  @Input() maxDepth: number;
  @Input() alwaysTrueAvailable: boolean;
  @Input() isViewOnly = false;
  @Output() logicChanged = new EventEmitter<GlobalValueLogicGroup<T, ValueLogicResult<T>>>();
  @Output() validChange = new EventEmitter<boolean>();

  componentForView: FormDefinitionComponent;
  conditionsValid: boolean;
  showDateCalculationCheckbox: boolean;
  resultTypeOptions: TypeaheadSelectOption[] = [];
  setValueOfComponentOptions: TypeaheadSelectOption<LogicColumn<T>>[];
  ConditionalLogicResultType = ConditionalLogicResultType;
  formGroup: TypeSafeFormGroup<ValueLogicBuilderFormGroup<T, ValueLogicResult<T>>>;
  staticValueGroup: FormComponentGroup;
  calculationOptionsFormGroup: TypeSafeFormGroup<RelativeDateCalculationConfig>;
  $formAnswerType = new TypeToken<FormAnswerValues>();
  isAfterInit = false;

  constructor (
    private logicBuilderService: LogicBuilderService,
    private i18n: I18nService,
    private formBuilder: TypeSafeFormBuilder,
    private componentHelper: ComponentHelperService,
    private currencyService: CurrencyService,
    private clientSettingsService: ClientSettingsService,
    private userService: UserService
  ) { }

  get isOtherColumn () {
    return this.logic?.resultType === ConditionalLogicResultType.OTHER_COLUMN;
  }

  ngOnInit () {
    if (!this.logic || this.availableColumns.length === 0) {
      this.setLogicToDefault();
    }
    this.handleCurrencyResults();
    this.setValueOfComponentOptions = this.sourceColumn?.otherColumnOptions;
    this.setFormGroups();
    this.setDateOptions();
    this.componentForView = {
      ...this.component,
      label: this.i18n.translate(
        'common:textValueOfComponent',
        {},
        'Value of component'
      )
    };

    this.isAfterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.isAfterInit) {
      if (changes.logic) {
        if (this.logic.resultType !== this.formGroup.value.resultType) {
          this.formGroup.get('resultType').setValue(this.logic.resultType);
        }
        this.handleCurrencyResults();
      }
      if (changes.logicValueFormatType) {
        this.setDateOptions();
      }
    }
  }

  handleCurrencyResults () {
    if (this.logicValueFormatType === 'currency' && !this.logic.result && !this.isOtherColumn) {
      const currencyOptions = this.currencyService.getCurrencyOptionsForComponent(
        '',
        this.component.useCustomCurrency,
        this.component.customCurrency
      );
      this.logic = {
        ...this.logic,
        result: {
          amountEquivalent: null,
          amountForControl: null,
          amountInDefaultCurrency: null,
          currency: this.componentHelper.getCurrencyForFormFieldControl(
            '',
            this.component.useCustomCurrency,
            this.component.customCurrency,
            currencyOptions,
            this.clientSettingsService.defaultCurrency,
            this.userService.lastSelectedCurrency
          )
        }
      };
      this.logicChanged.emit(this.logic);
    }
  }

  setDateOptions () {
    const isDate = this.logicValueFormatType === 'date';
    this.resultTypeOptions = this.logicBuilderService.getResultTypeOptions(isDate);
    this.setShowDateCalculationCheckbox();
  }

  setFormGroups () {
    const staticResult = !this.isOtherColumn ? this.logic?.result : null;
    const setResult = this.isOtherColumn ? this.logic?.result : null;
    const applyCalculation = !!this.logic?.resultConfig?.constant;
    this.formGroup = this.formBuilder.group<ValueLogicBuilderFormGroup<T, ValueLogicResult<T>>>({
      resultType: [this.logic?.resultType || ConditionalLogicResultType.STATIC_VALUE, Validators.required],
      staticResult: [staticResult as FormAnswerValues],
      setResult: [setResult as LogicColumn<T>],
      applyCalculation
    }, {
      validator: [
        SiblingValueValidator(
          'setResult',
          'resultType',
          ConditionalLogicResultType.OTHER_COLUMN
        )
      ]
    });
    this.calculationOptionsFormGroup = this.logicBuilderService.getRelativeDateCalculationsFormGroup(
      this.logic?.resultConfig
    );
    const compKey = this.componentHelper.getAdaptedKeyFromComponentKey(this.component.key, true, false);
    this.staticValueGroup = this.formBuilder.group({
      [compKey]: [this.formGroup.value.staticResult]
    });
  }

  setLogicToDefault () {
    this.logic = this.logicBuilderService.getDefaultConditionalValueLogic();
    if (this.logicValueFormatType === 'currency') {
      this.logic = {
        ...this.logic,
        result: {
          amountEquivalent: null,
          amountForControl: null,
          amountInDefaultCurrency: null,
          currency: ''
        }
      };
    }
    // Conditions are required for value logic
    this.conditionsValidChange(false);
    this.logicChanged.emit(this.logic);
  }

  conditionsValidChange (valid: boolean) {
    this.conditionsValid = valid;
    this.updateValidity();
  }

  updateValidity () {
    this.checkForConditionlessAvailable();
    const valid = this.getValidity();
    this.validChange.emit(valid);
  }

  getValidity (): boolean {
    const hasValidValue = this.getValueValidity();
    const conditionlessRulePermitted = this.logic.evaluationType === EvaluationType.AlwaysTrue &&
      this.logic.conditions.length === 0 &&
      this.alwaysTrueAvailable;
    const relativeDateOperationValid = !this.formGroup.value.applyCalculation ||
      this.calculationOptionsFormGroup.valid;
    const valid = this.formGroup.valid &&
      (hasValidValue || relativeDateOperationValid) &&
      (this.conditionsValid || conditionlessRulePermitted);

    return valid;
  }

  getValueValidity (): boolean {
    const formValues = this.formGroup?.value;
    const hasStaticValue = !!formValues?.staticResult ||
      formValues?.staticResult === false ||
      formValues?.staticResult === 0;
    const setResultValid = this.getSetResultValidity(formValues?.setResult);
    const hasValidValue = (this.isOtherColumn && setResultValid) ||
      (!this.isOtherColumn && hasStaticValue);

    return hasValidValue;
  }

  getSetResultValidity (val: LogicColumn<T>) {
    return (val as unknown) !== '' &&
      val !== undefined &&
      val !== null;
  }

  handleLogicChange (group: LogicGroupType<T, ValueLogicResult<T>>) {
    this.logic = group as GlobalValueLogicGroup<T, ValueLogicResult<T>>;
    this.logicChanged.emit(this.logic);
  }

  handleResultTypeChange (resultType: ConditionalLogicResultType) {
    const result = this.getResultByResultType(resultType);
    this.logic = {
      ...this.logic,
      resultType,
      result
    };
    // reset the apply calc checkbox
    this.formGroup.get('applyCalculation').setValue(false);
    this.setShowDateCalculationCheckbox();
    this.handleLogicChange(this.logic);
    this.updateValidity();
  }

  setShowDateCalculationCheckbox () {
    const resultType = this.formGroup.value.resultType;
    this.showDateCalculationCheckbox = resultType !== ConditionalLogicResultType.STATIC_VALUE &&
      this.logicValueFormatType === 'date';
  }

  getResultByResultType (resultType: ConditionalLogicResultType) {
    let result;
    const formValues = this.formGroup.value;

    switch(resultType) {
      case ConditionalLogicResultType.OTHER_COLUMN:
        result = formValues.setResult;
        break;
      case ConditionalLogicResultType.RELATIVE_DATE:
        result = null;
        break;
      default:
      case ConditionalLogicResultType.STATIC_VALUE:
        result = formValues.staticResult;
        break;
    }

    return result;
  }

  checkForConditionlessAvailable () {
    if (this.logic.conditions.length === 0 && this.alwaysTrueAvailable) {
      this.logic.evaluationType = EvaluationType.AlwaysTrue;
    } else if (this.logic.conditions.length !== 0) {
      this.logic.evaluationType = EvaluationType.ConditionallyTrue;
    };
  }

  handleCalculationChange () {
    const formGroupVal = this.calculationOptionsFormGroup.value;
    this.logic = {
      ...this.logic,
      resultConfig: {
        operator: formGroupVal.operator,
        constant: formGroupVal.constant,
        constantUnits: formGroupVal.constantUnits
      }
    };
    this.handleLogicChange(this.logic);
    this.updateValidity();
  }

  handleCalculationUpdate () {
    const useCalculationDataOnForm = this.formGroup.value.applyCalculation;
    if (useCalculationDataOnForm) {
      this.handleCalculationChange();
    } else {
      this.logic = {
        ...this.logic,
        resultConfig: {
          operator: null,
          constant: null,
          constantUnits: null
        }
      };
      this.handleLogicChange(this.logic);
      this.updateValidity();
    }
  }

  onStaticValueChange (result: FormChanges) {
    const staticResult = result.value;
    this.formGroup.get('staticResult').setValue(staticResult);
    const logic: GlobalValueLogicGroup<T, FormAnswerValues> = {
      ...this.logic,
      result: result.value,
      resultType: this.formGroup.value.resultType
    };
    this.handleLogicChange(logic);
    this.updateValidity();
  }

  onSetValueComponentChange (column: LogicColumn<T>) {
    const logic: GlobalValueLogicGroup<T, LogicColumn<T>> = {
      ...this.logic,
      result: column,
      resultType: this.formGroup.value.resultType
    };
    this.handleLogicChange(logic);
    this.updateValidity();
  }
}
